h1,h2,h3,h4,h5,h5,p,span,a,div{
  font-family: 'Ubuntu', sans-serif;
}

body {
  background-color: #1B2931;
}

/*Custom classes*/

/* Fonts*/
.roboto {
  font-family: 'Roboto slab', serif;
}

.fixed {
  position: fixed;
}

/* Colors */
.color-dark-blue{
  color: @yt-dark-blue;
}

.color-light-blue{
  color: @yt-light-blue;
}


.block-white-blue {
  background-color: #fff;

  .block-title {
    color: @yt-light-blue;
  }

  .text-muted {
    color: @yt-light-blue;
  }
}

.block-inverse {
  background-color: @yt-light-blue;
}

.btn {
  border-radius: 3px;
  a {
    text-decoration: none;
    color: inherit;
  }
}

.btn-primary {
  background-color: @yt-light-blue;
  border-color: @yt-light-blue;
  text-transform: uppercase;
}

.btn-primary:hover {
  background-color: @yt-light-blue;
  border-color: @yt-light-blue;
  color: @yt-dark-blue;
}

.btn-secondary {
  background-color: #fff;
  border-color: #fff;
  color: @yt-light-blue;
  text-transform: uppercase;
}

.btn-secondary:hover {
  color: @yt-dark-blue;
}

.btn-primary-outline {
  color: #fff;
  border-color: #fff;
}
.btn-primary-outline:hover {
  background-color: #fff;
  color: @yt-dark-blue;
}

.btn-primary-outline-inverse{
  border-color: @yt-dark-blue;
  color: @yt-dark-blue;
}

.btn-primary-outline-inverse:hover{
  color: #fff;
  border-color: #fff;
  background-color: @yt-dark-blue;
}

input {
  outline: none;
}

.panel {
  box-shadow: none;
}


.light-description-text{
  font-size: 1.8rem;
  color: @yt-light-blue;
}

.main-form-type {
  color: @yt-light-blue;

  .form-group-title {
    text-transform: uppercase;
    color: @yt-dark-blue;
    font-weight: 400;
  }

  .text-input {
    border: none;
    background: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid @yt-light-blue;
    padding-left: 0;
    color: @yt-dark-blue;
  }
  .text-input:focus{
    border-color: @yt-dark-blue;
  }

  .control-label {
    color: @yt-light-blue;
  }

  .yt-select {
    border-bottom: 1px solid @yt-light-blue;
    position: relative;

    select{
      box-shadow: none;
      border: none;
      border-radius: 0;
      -webkit-appearance: none;
      background: transparent;
      padding-right: 3rem;
      padding-left: 0;
    }
  }

  .yt-select:after {
    content: "V";
    position:absolute;
    top:1rem;
    right: 1rem;
    z-index: 1;

  }

  .inline-input-group {

    display: table-cell;
    vertical-align: middle;

    label{
      float: left;
      margin-bottom: 0;
      padding: 6px 12px;
    }

    span{
      display: block;
      overflow: hidden;
    }
  }

  .form-textarea{
    box-shadow: none;
    border: 1px solid @yt-light-blue;
    border-radius: 0;
  }

  .custom-checkbox{
    a {
      color: @yt-dark-blue;
    }
  }
}





.header-top {
  border: none;
  min-height: 20px;
  font-size: 1.2rem;
  color: @yt-light-blue;
  margin-bottom: 0px;

  .language-selector{
    position: relative;

    .balloon {
      width: auto;
      height: 1.2rem;
      padding-right: 0.5rem;
    }
  }

  .language-selector:after{
    content: "V";
    position:absolute;
    top:0rem;
    right: 0rem;
    z-index: 1;
  }

  ul.language-menu {
    margin-top: 1rem;
    border-radius: 0.5rem;

    li a{
      color: @yt-light-blue;
    }
  }
}

.header-bottom.navbar {
  margin-bottom: 0px;

  .yt-logo>img{
    max-width: 19rem;
  }

  .navbar-toggle{
    font-size: 3.4rem;
  }
}

.wrapper-affix {
  min-height: 98px;
}

.navbar-wrapper{
  min-height: 98px;
  border-bottom: 1px solid @yt-light-blue;

  .yt-border {
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, @yt-light-blue, @yt-dark-blue );
  }

  .call {
    a {
      color: @yt-light-blue !important;
      text-decoration: none;
      display: inline-block;
      padding: 0 !important;
      line-height: 1rem;
    }
  }
}

.stage {
  overflow-x: hidden;
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
}

//Mobile Menu
.stage-shelf{
  background-color: #fff;

  .fixed {
    height: 100%;
    width: 100%;
    background-color: #fff;
    top:0;

    .nav-stacked li a{
      color: @yt-dark-blue;
    }

    .inner-nav {

    }
  }

  .mobile-language-switcher {
    .balloon-xs {
      width: auto;
      height: 1.2rem;
      padding-right: .5rem;
      display: inline-block;
    }
  }
  .mobile-language-switcher li a{
    color: @yt-light-blue !important;
  }
}

.affix {
  width: 100%;
  max-width: 1600px;
  background-color: white;
  padding-bottom: 21px;
  z-index: 1030;
  box-sizing: border-box;
  border-radius: 0;
  box-shadow: 0px 2px 13px rgba(44, 54, 69, 0.2);
  min-height: 70px;

  .header-top {
    display: none;
  }

  .header-bottom {
    padding-top: 1.2rem;

    .yt-logo {
      padding-top: 0.5rem;
    }
  }
}

.header-top {
  padding-top: 0.8rem;

  .header-top-item {
    cursor: pointer;

    a {
      line-height: 1rem;
      display: inline;
      color: @yt-light-blue !important;
      padding: 0px;

    }
  }
  .header-top-item.active {
    a {
      border-bottom: 2px solid @yt-light-blue;
      padding-bottom: 0.5rem;
      pading-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .language-selector.header-top-item {
    margin-right: 3.2rem;
  }
}

.header-bottom .nav {
  li {
    a {
      color: @yt-dark-blue;
      font-weight: bold;

      :hover {
        color: @yt-light-blue;
      }
    }
  }

  li.active {
    a {
      color: @yt-light-blue;
    }
  }
}

h1 {
  color: @yt-dark-blue;
}

.lead{
  color: #fff;
}
//Home page
.app-header{
  background: url("/imgs/hp_new_2.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position-y: 40%;
  min-height: 60rem;
}

.app-join {
  background: url("/imgs/hp_2_1.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position-y: 10%;
  min-height: 40rem;
}
.main-message {
  padding-bottom: 10rem;
}
.text-muted {
  font-weight: 300;
}
.get-quote {
  margin-top: 2rem;
}

.callout {
  margin-top: 6rem;
}

.callout-content {
  text-transform: uppercase;
}

.callout-secondary {
  background-color: none;
  border-left: none;
}

.app-specialized {

  padding-top: 2.1rem;

  h3 {
    color: @yt-dark-blue;
    font-weight: 600;
    font-size: 2.4rem;
  }

  p {
    color: @yt-light-blue;
  }

  .percentage {

    margin-left: auto;
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
    width: 13rem;
    height: 13rem;
    position: relative;

    .inside-percentage {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
    }

    p {
      top: 6rem;
      left: 13rem;
      line-height: 13rem;
      font-size: 2rem;
      font-weight: 600;
    }

//    border: 15px solid;
//    border-color: rgba(75,166,223,0.4);
//    border-radius: 100%;
  }

  .p42 {
    background-image: url("/imgs/yt-42.png");
  }

  .p31 {
    background-image: url("/imgs/yt-31.png");
  }

  .p27 {
    background-image: url("/imgs/yt-27.png");
  }

  .quotes-slider {
    text-align: left;

    .bqstart {
      font-size: 11rem;
      color: @yt-dark-blue;
      height: 3.2rem;
      line-height: 1.2rem;

      span {
        position: absolute;
        margin-top: 4.2rem;
        margin-left: 4rem;
      }
    }

    .bqend {
      font-size: 11rem;
      color: @yt-dark-blue;
      height: 3.2rem;
      line-height: 1.2rem;

      div{
        margin-right: 3rem;
      }

      span {
        position: absolute;
        margin-top: 4.2rem;
      }
    }
    .main-text{
      p {
        color: @yt-dark-blue;
        font-weight: 300;
        text-transform: none;
      }

      margin-left: 9rem;
    }

    .sub-text {
      color: @yt-light-blue;
      text-transform: uppercase;
      margin-left: 9rem;
    }
  }

  .slick-slide {
    margin-bottom: 5px;
  }

  .slick-prev:before, .slick-next:before {
    color: @yt-light-blue;
  }

  .quotes-line {
    width: 100%;
  }

  .client-logo {
    line-height: 10rem;
    text-align: center;

    a {
      line-height: 100%;
    }
    img {
      height: auto;
      width: 15rem;
      max-width: 100%;
      line-height: 100%;
      filter: grayscale(1);
      vertical-align: middle;
      display: inline-block;
    }

    a:focus, img:focus {
      outline:none;
    }

    img:hover {
      filter: none;
    }
  }
  .client-logo::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .client-logo:focus {
    outline:none;
  }
}

.app-join {
  .center-block {
    float: none;
  }
}

//Services
.services {
  .app-services {
    @-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
    @-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
    @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

    @keyframes slide-in {
      100% { transform: translateY(0%); }
    }

    @-webkit-keyframes slide-in {
      100% { -webkit-transform: translateY(0%); }
    }

    .service {
      cursor: pointer;

      .service-logo {
        height: 11rem;
      }

      .service-title {
        text-transform: uppercase;
        color: @yt-light-blue;
      }

      .service-description {
        display: none;
        opacity: 0;
        transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
      }
    }

    .service:hover {
      .service-logo {
        display: none;
      }
      div.service-description {
        height: 11rem;
        display: block !important;
        color: @yt-dark-blue;

        -webkit-animation:fadeIn ease-in 0.7s forwards, slide-in 0.7s forwards;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
        -moz-animation:fadeIn ease-in 0.7s forwards, slide-in 0.7s forwards;
        animation:fadeIn ease-in 0.7s forwards, slide-in 0.7s forwards;
      }
    }
  }
  .app-languages {
    color: @yt-light-blue;
    font-size: 1.8rem;

    h1, h2, h3 {
      color: @yt-dark-blue;
    }

    p a {
      color: @yt-dark-blue;
    }

    .sexy_line {
      margin: 25px 0;
      height: 1px;
      background: black;
      background: -webkit-gradient(linear, 0 0, 100% 0, from(white), to(white), color-stop(50%, @yt-dark-blue));
    }

    .service-section-header{
      h3{
        position: relative;
        text-align: center;

        span{
          background: #fff;
          padding: 0 15px;
          position: relative;
          z-index: 1;
        }
      }

      h3:before {
        background: -webkit-gradient(linear, 0 0, 100% 0, from(white), to(white), color-stop(50%, @yt-dark-blue));
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        top: 50%;
        width: 100%;
      }

      h1:before {
        left: 0;
      }
    }

    .language-flag {
      font-size: 10rem;
      span{
        border-radius: 50%;
        filter: grayscale(0.4);
      }

      .flag-icon-ru {
        background-position-y: 2px;

      }
    }
    .language-name {
      color: @yt-light-blue;

    }

    .how-we-work, .pricing{

      .step-item {
        img {
          height: 12rem;
        }

        .step-pic {
          max-width: 12rem;
          margin-left: auto;
          margin-right: auto;
          position: relative;
        }

        .step-number {
          position: absolute;
          top: -21px;
          left: 0px;
          color: white;
          width: 42px;
          height: 42px;
          line-height: 32px;
          background: linear-gradient(to right, @yt-dark-blue, @yt-light-blue);
          border-radius: 50%;
          border: 5px solid #fff;
          text-decoration: none;
          font-weight: 700;
          font-size: 2.8rem;
          padding-left: 1px;
        }
      }
      .step-text {
        text-transform: uppercase;
        font-size: 1.5rem;
      }

    }

    .payments-info{
      font-size:1.5rem;
    }
  }
}

//About
.app-about {
  background: url("/imgs/teampic.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position-y: 0rem;
  min-height: 40rem;
  display: flex;
  justify-content: center;

  .container {
    align-self: center;
  }
}

.team {
  .picture{
    width: 250px;
    height: 250px;
    background-color: transparent;

    img {
      border-radius: 0.5rem;
      height: 100%;
    }
  }
  .girls-sm {
    font-size: 3rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  .who {
    .name {
      color: @yt-dark-blue;
      font-weight: 600;
      font-size: 1.6rem;
    }

    .title {
      color: @yt-light-blue;
      font-weight: 400;
    }
    span {
      display: block;
      text-transform: uppercase;
    }
  }

  .what {
    color: @yt-dark-blue;
    a {
      color: @yt-light-blue;
    }
  }

  h2 {
    color:@yt-dark-blue;
  }
}

#map {
  width: 100%;
  height: 80%;

}

.contact-address {
  .country{
    color: @yt-dark-blue;
    text-transform: uppercase;
    font-weight: bold;
  }

  .address{
    color: @yt-light-blue;
    display: block;
    clear: both;
  }

  .phone {
    color: @yt-dark-blue;
  }
}

//Freelance pool
.join{
  .app-join-us-page {
    background: url("/imgs/j_2.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position-y: 0;
    min-height: 60rem;
  }

  .pool-characteristics {
    color: @yt-dark-blue;

    .plus-icon {
      display: inline-block;

    }
  }
  .join-languages {
    .native-languages {
      overflow: hidden;

      .control-label {
        float:left;
        display:block;
      }

      .native-dropdowns {
        overflow: hidden;
      }
      .n-c {
        cursor: pointer;
      }
      .add-native-language{
        cursor: pointer;
      }
    }

    .pair-languages{
      .pair-cross {
        float: left;
        cursor: pointer;
      }

      .pair-to {
        span {
          line-height: 35px;
        }
      }
    }
  }

  .check-terms-cond{

    strong {
      color: @yt-dark-blue;
    }
  }
}

.app-blog {
  h1,h2,h3,h4,h5,h5 {
    color: @yt-dark-blue;
  }
}

.app-footer {
  background-color: @yt-dark-blue;
  padding-top: 3rem;

  border-bottom: 1px solid;
  border-color: @yt-light-grey;

  .newsletter-form {
    .text {
      background-color: #2d4488;
      border: none;
      border-bottom: 1px solid #fff;
      width: 25rem;
    }

    .spinner {
      display: inline-block;
      opacity: 0;
      width: 0;

      -webkit-transition: opacity 0.25s, width 0.25s;
      -moz-transition: opacity 0.25s, width 0.25s;
      -o-transition: opacity 0.25s, width 0.25s;
      transition: opacity 0.25s, width 0.25s;
    }

    .has-spinner.active {
      cursor:progress;
    }

    .has-spinner.active .spinner {
      opacity: 1;
      width: auto; /* This doesn't work, just fix for unkown width elements */
    }

    .has-spinner.btn.active .spinner {
      width: 16px;
    }

    @-webkit-keyframes rotating /* Safari and Chrome */ {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes rotating {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    .rotating {
      -webkit-animation: rotating 2s linear infinite;
      -moz-animation: rotating 2s linear infinite;
      -ms-animation: rotating 2s linear infinite;
      -o-animation: rotating 2s linear infinite;
      animation: rotating 2s linear infinite;
    }
  }

  span.icon {
    font-size: 5rem;
    color: #ffffff;
  }
}

footer {
  background-color: @yt-light-blue;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;

  color: #fff;
  font-size: 1.3rem;

  .logo-footer {
    max-width: 50%;
    height: auto;
    width: 11rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: -1rem;
  }
}

//Terms & Conditions
.terms-conditions {
  h4, h2 {
    color: @yt-light-blue;
  }

  ol li {
    margin-bottom: 2rem;
    color: @yt-dark-blue;
  }
}

//404
.error-not-found {
  .icon-404 {
    font-size: 10rem;
    color: @yt-dark-blue;

  }
}

//Backend
.admin-backend {
  background-color: #ffffff;

  .navbar {
    border-radius: 0px;
    border: none;

    border-bottom:1px solid @yt-dark-blue;
  }

  .menu {
    border-right: 1px solid @yt-dark-blue;
  }
}

@media screen and (max-width: 480px){
  #grid[data-columns]::before {
    content: '1 .column.size-1of1';
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  #grid[data-columns]::before {
    content: '2 .column.size-1of2';
  }
}
@media screen and (min-width: 769px) {
  #grid[data-columns]::before {
    content: '3 .column.size-1of3';
  }
}

/* Again, you’re free to use and define the classes: */
.column { float: left; }
.size-1of1 { width: 100%; }
.size-1of2 { width: 50%; }
.size-1of3 { width: 33.333%; }

@media(max-width: @screen-xs-max){
  .yt-logo {
    padding-top: 0.5rem;
    padding-left: 1rem;
  }

  .text-normal-xs {
    font-size: 1.2rem;
  }

  .wrapper-affix-xs {
    min-height: 0;
    height: 7rem;

    .navbar-wrapper {
      height: 7rem;
    }
  }

  .app-specialized p {
    font-size: 1.2rem;
  }

  .app-specialized .percentage {
    width: 10rem;
    height: 10rem;

    p {
      line-height: 10rem;
      font-size: 1.2rem;
    }
  }

  .app-specialized {
    .percentage-text {
      text-align: center;
      width: 10rem;
    }
  }

  .services .app-services .service .service-logo, .pricing .pricing-item .price-pic img {
    height: 8rem !important;
  }

  .app-footer {
    .social-media {
      span.icon {
        font-size: 4rem;
      }
    }
  }

  .app-join-us-page, .app-header {
    min-height: 40rem !important;
  }
}
@media(min-width: @screen-sm-min){}  /* deprecated: @screen-tablet, or @screen-sm */
@media(min-width: @screen-md-min){
  .app-about {
    min-height: 50rem;
  }
}  /* deprecated: @screen-desktop, or @screen-md */
@media(min-width: @screen-lg-min){
  .app-about {
    min-height: 60rem;
  }
}  /* deprecated: @screen-lg-desktop, or @screen-lg */
